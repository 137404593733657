import React from 'react'

/**
 * @param size  width and height of the Loader
 */
const Loader = ({ size = 40 }: { size?: number }) => (
  <svg
    version='1.1'
    id='loader-1'
    x={`${size}px`}
    y={`${size}px`}
    width={`${size}px`}
    height={`${size}px`}
    viewBox='0 0 50 50'
  >
    <path
      fill='#4f98a3'
      d='M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z'
    >
      <animateTransform
        attributeType='xml'
        attributeName='transform'
        type='rotate'
        from='0 25 25'
        to='360 25 25'
        dur='0.6s'
        repeatCount='indefinite'
      />
    </path>
  </svg>
)

global.Loader = Loader
Loader.displayName = 'Loader'

export default Loader
