import './utils'
import { Actions } from './app-actions'
import produce, { enableES5 } from 'immer'
// eslint-disable-next-line no-unused-vars
import {
  itemError,
  itemLoaded,
  itemLoading,
  itemSaving,
  itemSaved,
} from './utils/reducer-helpers'
import { AppState, RequestTypes } from './state-type'

enableES5() // required for react native hermes

// Adds infinite scroll
const adjustInfinite = (action, state) => {
  if (
    action.originalAction.data.infinite &&
    action.originalAction.data.page > 1 &&
    state?.[action.originalAction.data.organisationId].content
  ) {
    action.data = {
      ...action.data,
      content: state?.[
        action.originalAction.data.organisationId
      ].content.concat(action.data.content),
    }
  }
}
const defaultReducer = produce((state: AppState, action): AppState | void => {
  if (typeof window === 'undefined') {
    API.log('SERVER', action.type, action.data)
    if (action.type.includes('ERROR')) {
      API.log('SERVER', action)
    }
  } else {
    API.log('DISPATCHER', action.type)
  }
  switch (action.type) {
    case Actions.LOGIN_LOADED:
    case Actions.RESET_PASSWORD_LOADED:
    case Actions.FORGOT_PASSWORD_LOADED:
    case Actions.CHANGE_PASSWORD_LOADED:
    case Actions.UPDATE_USER_LOADED:
    case Actions.CONFIRM_EMAIL_LOADED:
    case Actions.REGISTER_LOADED:
    case Actions.GET_PROFILE_LOADED:
      itemLoaded(state, 'profile', action)
      break
    case Actions.LOGIN_ERROR:
    case Actions.RESET_PASSWORD_ERROR:
    case Actions.FORGOT_PASSWORD_ERROR:
    case Actions.CHANGE_PASSWORD_ERROR:
    case Actions.CONFIRM_EMAIL_ERROR:
    case Actions.REGISTER_ERROR:
    case Actions.UPDATE_USER_ERROR:
    case Actions.GET_PROFILE_ERROR:
      itemError(state, 'profile', action)
      break
    case Actions.REGISTER:
    case Actions.UPDATE_USER:
    case Actions.CONFIRM_EMAIL:
    case Actions.LOGIN:
    case Actions.RESET_PASSWORD:
    case Actions.FORGOT_PASSWORD:
    case Actions.CHANGE_PASSWORD:
    case Actions.GET_PROFILE:
      itemLoading(state, 'profile')
      break
    case Actions.CLEAR_USER:
      if (state.profile.id) {
        // @ts-ignore
        API.push?.unsubscribe(`${state.profile.id}`)
      }
      state.profile = null
      break
    case Actions.STARTUP_LOADED:
      Object.keys(action.data).map((k) => {
        state[k] = action.data[k]
      })
      break
    case Actions.SET_ACTIVE_SCREEN:
      itemLoaded(state, 'activeScreen', action, true)
      break
    case Actions.GET_PROJECT:
      return itemLoading(state, 'project')
    case Actions.GET_PROJECT_LOADED:
      action.index = action.originalAction.data.id
      return itemLoaded(state, 'project', action)
    case Actions.GET_PROJECT_ERROR:
      return itemError(state, 'project', action)
    case Actions.UPDATE_PROJECT:
      return itemSaving(state, 'project')
    case Actions.UPDATE_PROJECT_LOADED:
      action.index = action.originalAction.data.id
      return itemSaved(state, 'project', action)
    case Actions.UPDATE_PROJECT_ERROR:
      return itemError(state, 'project', action)
    case Actions.CREATE_ORGANISATION:
      return itemSaving(state, 'organisation')
    case Actions.CREATE_ORGANISATION_LOADED:
      return itemSaved(state, 'organisation', action)
    case Actions.CREATE_ORGANISATION_ERROR:
      return itemError(state, 'organisation', action)
    case Actions.GET_ORGANISATION:
      return itemLoading(state, 'organisation')
    case Actions.GET_ORGANISATION_LOADED:
      return itemLoaded(state, 'organisation', action)
    case Actions.CREATE_ASSESSMENT:
      return itemSaving(state, 'assessment')
    case Actions.CREATE_ASSESSMENT_LOADED:
      return itemSaved(state, 'assessment', action)
    case Actions.CREATE_ASSESSMENT_ERROR:
      return itemError(state, 'assessment', action)
    case Actions.GET_ASSESSMENT:
      return itemLoading(state, 'assessment')
    case Actions.GET_ASSESSMENT_LOADED:
      action.index = action.originalAction.data.id
      return itemLoaded(state, 'assessment', action)
    case Actions.GET_ASSESSMENT_ERROR:
      return itemError(state, 'assessment', action)
    case Actions.GET_ASSESSMENT_SUMMARIES:
      return itemLoading(state, 'assessmentSummaries')
    case Actions.GET_ASSESSMENT_SUMMARIES_LOADED:
      return itemLoaded(state, 'assessmentSummaries', action)
    case Actions.GET_ASSESSMENT_SUMMARIES_ERROR:
      return itemError(state, 'assessmentSummaries', action)
    case Actions.UPDATE_ASSESSMENT:
      return itemSaving(state, 'assessment')
    case Actions.UPDATE_ASSESSMENT_LOADED:
      const originalAction: RequestTypes['updateAssessment'] =
        action.originalAction.data
      action.index = originalAction.id
      return itemSaved(state, 'assessment', action)
    case Actions.UPDATE_ASSESSMENT_ERROR:
      return itemError(state, 'assessment', action)
    case Actions.COMPLETE_ASSESSMENT:
      return itemSaving(state, 'assessment')
    case Actions.COMPLETE_ASSESSMENT_LOADED:
      const oAction: RequestTypes['completeAssessment'] = action.data
      action.index = oAction.id
      return itemSaved(state, 'assessment', action)
    case Actions.COMPLETE_ASSESSMENT_ERROR:
      return itemError(state, 'assessment', action)
    case Actions.SET_ASSESSMENT_SELECTED_ANSWERS:
      const actionData: RequestTypes['setAssessmentSelectedAnswers'] =
        action.data
      const assessmentId = actionData?.id
      state.assessmentSelectedAnswers = {
        ...state.assessmentSelectedAnswers,
        [assessmentId]: {
          ...state?.assessmentSelectedAnswers?.[assessmentId],
          ...state?.assessmentSelectedAnswers?.assessmentId,
          ...actionData,
        },
      }
      return state
    case Actions.GET_ASSESSMENT_REPORT:
      return itemLoading(state, 'assessmentReport')
    case Actions.GET_ASSESSMENT_REPORT_LOADED:
      return itemLoaded(state, 'assessmentReport', action)
    case Actions.GET_ASSESSMENT_REPORT_ERROR:
      return itemError(state, 'assessmentReport', action)
    case Actions.SET_ASSESSMENT_PROGRESS:
      const aData: RequestTypes['setAssessmentProgress'] = action.data
      const assessmentID = aData?.id
      const progress = aData?.progress
      state.assessmentProgress = {
        ...state?.assessmentProgress,
        [assessmentID]: [...progress],
      }
      return state
    case Actions.SET_ACTIVE_SUCCESS_FACTOR:
      const data: RequestTypes['setActiveSuccessFactor'] = action.data
      state.activeSuccessFactor = data
      return state
    case Actions.CREATE_CONFIRM_SIGNIN:
      return itemSaving(state, 'confirmSignin')
    case Actions.CREATE_CONFIRM_SIGNIN_LOADED:
      return itemSaved(state, 'confirmSignin', action)
    case Actions.CREATE_CONFIRM_SIGNIN_ERROR:
      return itemError(state, 'confirmSignin', action)
    case Actions.DELETE_USER:
      return itemSaving(state, 'user')
    case Actions.DELETE_USER_LOADED:
      return itemSaved(state, 'user', action)
    case Actions.DELETE_USER_ERROR:
      return itemError(state, 'user', action)
    case Actions.CREATE_USER:
      return itemSaving(state, 'user')
    case Actions.CREATE_USER_LOADED:
      return itemSaved(state, 'user', action)
    case Actions.CREATE_USER_ERROR:
      return itemError(state, 'user', action)
    case Actions.GET_USER:
      return itemLoading(state, 'user')
    case Actions.GET_USER_LOADED:
      return itemLoaded(state, 'user', action)
    case Actions.GET_USER_ERROR:
      return itemError(state, 'user', action)
    case Actions.CREATE_UPLOAD_FILE:
      return itemSaving(state, 'uploadFile')
    case Actions.CREATE_UPLOAD_FILE_LOADED:
      return itemSaved(state, 'uploadFile', action)
    case Actions.CREATE_UPLOAD_FILE_ERROR:
      return itemError(state, 'uploadFile', action)
    case Actions.CREATE_PROJECT:
      return itemSaving(state, 'project')
    case Actions.CREATE_PROJECT_LOADED:
      return itemSaved(state, 'project', action)
    case Actions.CREATE_PROJECT_ERROR:
      return itemError(state, 'project', action)
    case Actions.GET_USERS:
      return itemLoading(state, 'users')
    case Actions.GET_USERS_LOADED:
      action.index = action.originalAction.data.orgId
      return itemLoaded(state, 'users', action)
    case Actions.GET_USERS_ERROR:
      return itemError(state, 'users', action)
    case Actions.UPDATE_USER_ORGANISATION_PERMISSIONS:
      return itemSaving(state, 'userOrganisationPermissions')
    case Actions.UPDATE_USER_ORGANISATION_PERMISSIONS_LOADED:
      return itemSaved(state, 'userOrganisationPermissions', action)
    case Actions.UPDATE_USER_ORGANISATION_PERMISSIONS_ERROR:
      return itemError(state, 'userOrganisationPermissions', action)
    case Actions.GET_USER_PROJECT_PERMISSIONS:
      return itemLoading(state, 'userProjectPermissions')
    case Actions.GET_USER_PROJECT_PERMISSIONS_LOADED:
      action.index = action.originalAction.data.user
      return itemLoaded(state, 'userProjectPermissions', action)
    case Actions.GET_USER_PROJECT_PERMISSIONS_ERROR:
      return itemError(state, 'userProjectPermissions', action)
    case Actions.UPDATE_USER_PROJECT_PERMISSIONS:
      return itemSaving(state, 'userProjectPermissions')
    case Actions.UPDATE_USER_PROJECT_PERMISSIONS_LOADED:
      return itemSaved(state, 'userProjectPermissions', action)
    case Actions.UPDATE_USER_PROJECT_PERMISSIONS_ERROR:
      return itemError(state, 'userProjectPermissions', action)
    case Actions.CREATE_ORGANISATION_INVITE:
      return itemSaving(state, 'organisationInvite')
    case Actions.CREATE_ORGANISATION_INVITE_LOADED:
      return itemSaved(state, 'organisationInvite', action)
    case Actions.CREATE_ORGANISATION_INVITE_ERROR:
      return itemError(state, 'organisationInvite', action)
    case Actions.CREATE_PROJECT_INVITE:
      return itemSaving(state, 'projectInvite')
    case Actions.CREATE_PROJECT_INVITE_LOADED:
      return itemSaved(state, 'projectInvite', action)
    case Actions.CREATE_PROJECT_INVITE_ERROR:
      return itemError(state, 'projectInvite', action)
    case Actions.CHECK_ISADMIN:
      return itemLoading(state, 'isadmin');
    case Actions.CHECK_ISADMIN_LOADED:
      return itemLoaded(state, 'isadmin', action);
    case Actions.CHECK_ISADMIN_ERROR:
      return itemError(state, 'isadmin', action);
    // END OF REDUCER
    // KEEP THE ABOVE LINE IN, IT IS USED BY OUR CLI
    default:
      break
  }
})

export default defaultReducer
