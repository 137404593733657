import React, { FunctionComponent } from 'react'
import Head from 'next/head'
import { useTheme } from '../pages/superadmin/ThemeContext'


type ComponentType = {
    title: string
}

const ProjectTitle: FunctionComponent<ComponentType> = ({
    title

}) => {
    const {themeColors } = useTheme();
    //debugger

    // if (isComplete) {
    //   return <CompleteStage>{children}</CompleteStage>
    // }
    return (
        <Head>
            <title>{themeColors.title}</title>
        </Head>
    )
}

export default ProjectTitle
