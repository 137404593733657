import { FC } from 'react'
import useLoggedInRedirect from '../../common/providers/useLoggedInRedirect' // we need this to make JSX compile

type LoginPersistType = {}

const LoginPersist: FC<LoginPersistType> = ({ children }) => {
  useLoggedInRedirect()
  return <>{children}</>
}

export default LoginPersist