import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import {
  AppState,
  OrganisationRole,
  ProjectRole,
  RequestTypes,
} from '../state-type'
import { useCallback } from 'react'

type UseProfile = {
  profile: AppState['profile']
  profileLoading: AppState['profileLoading']
  profileSaving: AppState['profileSaving']
  profileError: AppState['profileError']
  getOrganisationRole: (orgId: string) => OrganisationRole
  getProjectRole: (orgId: string, projectId: string) => ProjectRole
  getProfile: (data: RequestTypes['getProfile'], callbacks?: Callbacks) => void
  updateProfile: (
    data: RequestTypes['updateProfile'],
    callbacks?: Callbacks,
  ) => void
  getAdminProfile: (data: RequestTypes['getProfile'], callbacks?: Callbacks) => void
  updateProfile: (
    data: RequestTypes['updateProfile'],
    callbacks?: Callbacks,
  ) => void
}

export default function useProfile(): UseProfile {
  const { profile, profileLoading, profileSaving, profileError } = useSelector(
    (state: AppState) => ({
      profile: state.profile,
      profileLoading: state.profileLoading,
      profileSaving: state.profileSaving,
      profileError: state.profileError,
    }),
  )
  const dispatch = useDispatch()
  const getProfile = useCallback(
    (data: RequestTypes['getProfile'], callbacks?: Callbacks) => {
      return dispatch(AppActions.getProfile(data, callbacks))
    },
    [dispatch],
    
  )
  const getAdminProfile = useCallback(
    (data: RequestTypes['getAdminProfile'], callbacks?: Callbacks) => {
      return dispatch(AppActions.getAdminProfile(data, callbacks))
    },
    [dispatch],
  )
  const updateProfile = useCallback(
    (data: RequestTypes['updateProfile'], callbacks?: Callbacks) => {
      return dispatch(AppActions.updateProfile(data, callbacks))
    },
    [dispatch],
  )

  const getOrganisationRole = (orgId: string) => {
    return profile?.organisations?.find((org) => org.id === orgId)?.role
  }

  const getProjectRole = (orgId: string, projectId: string) => {
    const orgRole = getOrganisationRole(orgId)
    if (orgRole === OrganisationRole.ADMIN) {
      return ProjectRole.ASSESSOR
    }
    return profile?.projects?.find((org) => org.id === projectId)?.role
  }

  return {
    profile,
    updateProfile,
    profileLoading,
    getOrganisationRole,
    getProjectRole,
    profileError,
    profileSaving,
    getProfile,
    getAdminProfile
  }
}
