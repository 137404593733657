import React, { useState } from 'react'
import cx from 'classnames'

export type Select = React.SelectHTMLAttributes<any> & {
  title?: string
  isValid?: boolean
  touched?: boolean
  children?: React.ReactNode
  errorMessage?: string
  label?: string
  containerStyle?: React.CSSProperties
}

const Select: React.FC<Select> = ({
  title,
  touched,
  label,
  isValid,
  children,
  errorMessage,
  containerStyle,
  ...props
}) => {
  const [shouldValidate, setShouldValidate] = useState<boolean>(false)
  return (
    <div style={containerStyle}>
      {title && (
        <label className='select__text' style={{ marginBottom: 7.5 }}>
          {title}
        </label>
      )}
      <div className='select'>
        <select
          {...props}
          onBlur={(e) => {
            setShouldValidate(true)
            props.onBlur && props.onBlur(e)
          }}
          className={cx(`${props.className || ''}`, {
            invalid: (touched || shouldValidate) && !isValid,
          })}
        >
          <option value='' disabled selected>
            {label || Strings.pleaseSelect}
          </option>
          {children}
        </select>
        <i className='select__icon fas fa-caret-down'></i>
      </div>
    </div>
  )
}

Select.displayName = 'Select'
global.Select = Select
export default Select
