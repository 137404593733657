import { AnyAction } from 'redux'
import { RequestTypes } from './state-type'

const BaseConstants = {
  LOGIN: 'LOGIN',
  LOGIN_LOADED: 'LOGIN_LOADED',
  LOGIN_ERROR: 'LOGIN_ERROR',
  REFRESH_TOKENS: 'REFRESH_TOKENS',

  REGISTER: 'REGISTER',
  REGISTER_LOADED: 'REGISTER_LOADED',
  REGISTER_ERROR: 'REGISTER_ERROR',

  STARTUP: 'STARTUP',
  STARTUP_LOADED: 'STARTUP_LOADED',
  STARTUP_ERROR: 'STARTUP_ERROR',

  LOGOUT: 'LOGOUT',
  CLEAR_USER: 'CLEAR_USER',
  REFRESH: 'REFRESH',

  'GET_PROFILE': 'GET_PROFILE',
  'GET_PROFILE_LOADED': 'GET_PROFILE_LOADED',
  'GET_PROFILE_ERROR': 'GET_PROFILE_ERROR',

  'UPDATE_PROFILE': 'UPDATE_PROFILE',
  'UPDATE_PROFILE_LOADED': 'UPDATE_PROFILE_LOADED',
  'UPDATE_PROFILE_ERROR': 'UPDATE_PROFILE_ERROR',

  CONFIRM_EMAIL: 'CONFIRM_EMAIL',
  CONFIRM_EMAIL_ERROR: 'CONFIRM_EMAIL_ERROR',
  CONFIRM_EMAIL_LOADED: 'CONFIRM_EMAIL_LOADED',

  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',
  UPDATE_USER_LOADED: 'UPDATE_USER_LOADED',

  SET_ACTIVE_SCREEN: 'SET_ACTIVE_SCREEN',

  'GET_ADMIN_PROFILE': 'GET_ADMIN_PROFILE',
}
export interface Callbacks {
  onSuccess?: (data: any) => void
  onError?: (data: any, originalError?: any) => void
}

const BaseActions = {
  login(data: Record<string, any>, callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.LOGIN,
      data,
      ...callbacks,
    }
  },
  // STARTUP
  startup(data: Record<string, any>, callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.STARTUP,
      data,
      ...callbacks,
    }
  },
  // REGISTER
  register(data: Record<string, any>, callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.REGISTER,
      data,
      ...callbacks,
    }
  },
  updateUser(data: Record<string, any>, callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.UPDATE_USER,
      data,
      ...callbacks,
    }
  },
  confirmEmail(
    data: Record<string, any>,
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CONFIRM_EMAIL,
      data,
      ...callbacks,
    }
  },
  logout(callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.LOGOUT,
      ...callbacks,
    }
  },

  getProfile(
    data: RequestTypes['getProfile'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_PROFILE,
      data,
      ...callbacks,
    }
  },
  getAdminProfile(
    data: RequestTypes['getAdminProfile'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_ADMIN_PROFILE,
      data,
      ...callbacks,
    }
  },

  updateProfile(
    data: RequestTypes['updateProfile'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.UPDATE_PROFILE,
      data,
      ...callbacks,
    }
  },
  setActiveScreen(name: string, navigator = 'root'): AnyAction {
    return {
      type: Actions.SET_ACTIVE_SCREEN,
      index: navigator,
      data: name,
    }
  },
}

// @ts-ignore
export const Actions = (global.Actions = Object.assign({}, BaseConstants, {
  'GET_PROJECT': 'GET_PROJECT',
  'GET_PROJECT_LOADED': 'GET_PROJECT_LOADED',
  'GET_PROJECT_ERROR': 'GET_PROJECT_ERROR',

  'UPDATE_PROJECT': 'UPDATE_PROJECT',
  'UPDATE_PROJECT_LOADED': 'UPDATE_PROJECT_LOADED',
  'UPDATE_PROJECT_ERROR': 'UPDATE_PROJECT_ERROR',

  'CREATE_ASSESSMENT': 'CREATE_ASSESSMENT',
  'CREATE_ASSESSMENT_LOADED': 'CREATE_ASSESSMENT_LOADED',
  'CREATE_ASSESSMENT_ERROR': 'CREATE_ASSESSMENT_ERROR',

  'GET_ASSESSMENT': 'GET_ASSESSMENT',
  'GET_ASSESSMENT_LOADED': 'GET_ASSESSMENT_LOADED',
  'GET_ASSESSMENT_ERROR': 'GET_ASSESSMENT_ERROR',

  'GET_ASSESSMENT_SUMMARIES': 'GET_ASSESSMENT_SUMMARIES',
  'GET_ASSESSMENT_SUMMARIES_LOADED': 'GET_ASSESSMENT_SUMMARIES_LOADED',
  'GET_ASSESSMENT_SUMMARIES_ERROR': 'GET_ASSESSMENT_SUMMARIES_ERROR',

  'UPDATE_ASSESSMENT': 'UPDATE_ASSESSMENT',
  'UPDATE_ASSESSMENT_LOADED': 'UPDATE_ASSESSMENT_LOADED',
  'UPDATE_ASSESSMENT_ERROR': 'UPDATE_ASSESSMENT_ERROR',

  'COMPLETE_ASSESSMENT': 'COMPLETE_ASSESSMENT',
  'COMPLETE_ASSESSMENT_LOADED': 'COMPLETE_ASSESSMENT_LOADED',
  'COMPLETE_ASSESSMENT_ERROR': 'COMPLETE_ASSESSMENT_ERROR',

  'SET_ASSESSMENT_SELECTED_ANSWERS': 'SET_ASSESSMENT_SELECTED_ANSWERS',

  'GET_ASSESSMENT_REPORT': 'GET_ASSESSMENT_REPORT',
  'GET_ASSESSMENT_REPORT_LOADED': 'GET_ASSESSMENT_REPORT_LOADED',
  'GET_ASSESSMENT_REPORT_ERROR': 'GET_ASSESSMENT_REPORT_ERROR',

  'DOWNLOAD_ASSESSMENT_REPORT': 'DOWNLOAD_ASSESSMENT_REPORT',
  'DOWNLOAD_ASSESSMENT_REPORT_LOADED': 'DOWNLOAD_ASSESSMENT_REPORT_LOADED',
  'DOWNLOAD_ASSESSMENT_REPORT_ERROR': 'DOWNLOAD_ASSESSMENT_REPORT_ERROR',

  'CREATE_ORGANISATION': 'CREATE_ORGANISATION',
  'CREATE_ORGANISATION_LOADED': 'CREATE_ORGANISATION_LOADED',
  'CREATE_ORGANISATION_ERROR': 'CREATE_ORGANISATION_ERROR',

  'GET_ORGANISATION': 'GET_ORGANISATION',
  'GET_ORGANISATION_LOADED': 'GET_ORGANISATION_LOADED',
  'GET_ORGANISATION_ERROR': 'GET_ORGANISATION_ERROR',

  'UPDATE_ORGANISATION': 'UPDATE_ORGANISATION',
  'UPDATE_ORGANISATION_LOADED': 'UPDATE_ORGANISATION_LOADED',
  'UPDATE_ORGANISATION_ERROR': 'UPDATE_ORGANISATION_ERROR',

  'SET_ASSESSMENT_PROGRESS': 'SET_ASSESSMENT_PROGRESS',

  'SET_ACTIVE_SUCCESS_FACTOR': 'SET_ACTIVE_SUCCESS_FACTOR',

  CREATE_OAUTH: 'CREATE_OAUTH',

  'CREATE_CONFIRM_SIGNIN': 'CREATE_CONFIRM_SIGNIN',
  'CREATE_CONFIRM_SIGNIN_LOADED': 'CREATE_CONFIRM_SIGNIN_LOADED',
  'CREATE_CONFIRM_SIGNIN_ERROR': 'CREATE_CONFIRM_SIGNIN_ERROR',
  'DELETE_USER': 'DELETE_USER',
  'DELETE_USER_LOADED': 'DELETE_USER_LOADED',
  'DELETE_USER_ERROR': 'DELETE_USER_ERROR',

  'CREATE_USER': 'CREATE_USER',
  'CREATE_USER_LOADED': 'CREATE_USER_LOADED',
  'CREATE_USER_ERROR': 'CREATE_USER_ERROR',

  'FORGOT_PASSWORD': 'FORGOT_PASSWORD',
  'FORGOT_PASSWORD_LOADED': 'FORGOT_PASSWORD_LOADED',
  'FORGOT_PASSWORD_ERROR': 'FORGOT_PASSWORD_ERROR',

  'RESET_PASSWORD': 'RESET_PASSWORD',
  'RESET_PASSWORD_LOADED': 'RESET_PASSWORD_LOADED',
  'RESET_PASSWORD_ERROR': 'RESET_PASSWORD_ERROR',

  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  CHANGE_PASSWORD_LOADED: 'CHANGE_PASSWORD_LOADED',
  CHANGE_PASSWORD_ERROR: 'CHANGE_PASSWORD_ERROR',

  'GET_USER': 'GET_USER',
  'GET_USER_LOADED': 'GET_USER_LOADED',
  'GET_USER_ERROR': 'GET_USER_ERROR',

  'UPDATE_USER': 'UPDATE_USER',
  'UPDATE_USER_LOADED': 'UPDATE_USER_LOADED',
  'UPDATE_USER_ERROR': 'UPDATE_USER_ERROR',

  'CREATE_UPLOAD_FILE': 'CREATE_UPLOAD_FILE',
  'CREATE_UPLOAD_FILE_LOADED': 'CREATE_UPLOAD_FILE_LOADED',
  'CREATE_UPLOAD_FILE_ERROR': 'CREATE_UPLOAD_FILE_ERROR',

  'CREATE_PROJECT': 'CREATE_PROJECT',
  'CREATE_PROJECT_LOADED': 'CREATE_PROJECT_LOADED',
  'CREATE_PROJECT_ERROR': 'CREATE_PROJECT_ERROR',

  'GET_USERS': 'GET_USERS',
  'GET_USERS_LOADED': 'GET_USERS_LOADED',
  'GET_USERS_ERROR': 'GET_USERS_ERROR',

  'UPDATE_USER_ORGANISATION_PERMISSIONS':
    'UPDATE_USER_ORGANISATION_PERMISSIONS',
  'UPDATE_USER_ORGANISATION_PERMISSIONS_LOADED':
    'UPDATE_USER_ORGANISATION_PERMISSIONS_LOADED',
  'UPDATE_USER_ORGANISATION_PERMISSIONS_ERROR':
    'UPDATE_USER_ORGANISATION_PERMISSIONS_ERROR',

  'GET_USER_PROJECT_PERMISSIONS': 'GET_USER_PROJECT_PERMISSIONS',
  'GET_USER_PROJECT_PERMISSIONS_LOADED': 'GET_USER_PROJECT_PERMISSIONS_LOADED',
  'GET_USER_PROJECT_PERMISSIONS_ERROR': 'GET_USER_PROJECT_PERMISSIONS_ERROR',

  'UPDATE_USER_PROJECT_PERMISSIONS': 'UPDATE_USER_PROJECT_PERMISSIONS',
  'UPDATE_USER_PROJECT_PERMISSIONS_LOADED':
    'UPDATE_USER_PROJECT_PERMISSIONS_LOADED',
  'UPDATE_USER_PROJECT_PERMISSIONS_ERROR':
    'UPDATE_USER_PROJECT_PERMISSIONS_ERROR',

  'CREATE_ORGANISATION_INVITE': 'CREATE_ORGANISATION_INVITE',
  'CREATE_ORGANISATION_INVITE_LOADED': 'CREATE_ORGANISATION_INVITE_LOADED',
  'CREATE_ORGANISATION_INVITE_ERROR': 'CREATE_ORGANISATION_INVITE_ERROR',

  'CREATE_PROJECT_INVITE': 'CREATE_PROJECT_INVITE',
  'CREATE_PROJECT_INVITE_LOADED': 'CREATE_PROJECT_INVITE_LOADED',
  'CREATE_PROJECT_INVITE_ERROR': 'CREATE_PROJECT_INVITE_ERROR',

  'MESSAGETEST': 'MESSAGETEST',

  'CHECK_ISADMIN': 'CHECK_ISADMIN',
  'CHECK_ISADMIN_LOADED': 'CHECK_ISADMIN_LOADED',
  'CHECK_ISADMIN_ERROR': 'CHECK_ISADMIN_ERROR',

  'MESSAGETEST': 'MESSAGETEST',
  'FETCH_THEME_ICONS': 'FETCH_THEME_ICONS',

  // END OF ACTION_STRINGS
}))

// @ts-ignore
export const AppActions = (global.AppActions = Object.assign({}, BaseActions, {
  getProject(
    data: RequestTypes['getProject'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_PROJECT,
      data,
      ...callbacks,
    }
  },

  updateProject(
    data: RequestTypes['updateProject'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.UPDATE_PROJECT,
      data,
      ...callbacks,
    }
  },

  createAssessment(
    data: RequestTypes['createAssessment'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_ASSESSMENT,
      data,
      ...callbacks,
    }
  },

  getAssessment(
    data: RequestTypes['getAssessment'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_ASSESSMENT,
      data,
      ...callbacks,
    }
  },

  updateAssessment(
    data: RequestTypes['updateAssessment'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.UPDATE_ASSESSMENT,
      data,
      ...callbacks,
    }
  },

  createOrganisation(
    data: RequestTypes['createOrganisation'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_ORGANISATION,
      data,
      ...callbacks,
    }
  },

  getOrganisation(
    data: RequestTypes['getOrganisation'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_ORGANISATION,
      data,
      ...callbacks,
    }
  },

  updateOrganisation(
    data: RequestTypes['updateOrganisation'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.UPDATE_ORGANISATION,
      data,
      ...callbacks,
    }
  },

  getAssessmentSummaries(
    data: RequestTypes['getAssessmentSummaries'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_ASSESSMENT_SUMMARIES,
      data,
      ...callbacks,
    }
  },

  completeAssessment(
    data: RequestTypes['completeAssessment'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.COMPLETE_ASSESSMENT,
      data,
      ...callbacks,
    }
  },

  setAssessmentSelectedAnswers(
    data: RequestTypes['setAssessmentSelectedAnswers'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.SET_ASSESSMENT_SELECTED_ANSWERS,
      data,
      ...callbacks,
    }
  },

  getAssessmentReport(
    data: RequestTypes['getAssessmentReport'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_ASSESSMENT_REPORT,
      data,
      ...callbacks,
    }
  },

  downloadAssessmentReport(
    data: RequestTypes['downloadAssessmentReport'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.DOWNLOAD_ASSESSMENT_REPORT,
      data,
      ...callbacks,
    }
  },

  setAssessmentProgress(
    data: RequestTypes['setAssessmentProgress'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.SET_ASSESSMENT_PROGRESS,
      data,
      ...callbacks,
    }
  },

  setActiveSuccessFactor(
    data: RequestTypes['setActiveSuccessFactor'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.SET_ACTIVE_SUCCESS_FACTOR,
      data,
      ...callbacks,
    }
  },

  createOauth(data: Record<string, any>, callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.CREATE_OAUTH,
      data,
      ...callbacks,
    }
  },

  createConfirmSignin(
    data: RequestTypes['createConfirmSignin'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_CONFIRM_SIGNIN,
      data,
      ...callbacks,
    }
  },

  deleteUser(
    data: RequestTypes['deleteUser'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.DELETE_USER,
      data,
      ...callbacks,
    }
  },

  createUser(
    data: RequestTypes['createUser'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_USER,
      data,
      ...callbacks,
    }
  },

  forgotPassword(
    data: RequestTypes['forgotPassword'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.FORGOT_PASSWORD,
      data,
      ...callbacks,
    }
  },

  resetPassword(
    data: RequestTypes['resetPassword'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.RESET_PASSWORD,
      data,
      ...callbacks,
    }
  },

  changePassword(
    data: Record<string, any>,
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CHANGE_PASSWORD,
      data,
      ...callbacks,
    }
  },

  getUser(data: RequestTypes['getUser'], callbacks: Callbacks = {}): AnyAction {
    return {
      type: Actions.GET_USER,
      data,
      ...callbacks,
    }
  },

  updateUser(
    data: RequestTypes['updateUser'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.UPDATE_USER,
      data,
      ...callbacks,
    }
  },

  createUploadFile(
    data: RequestTypes['createUploadFile'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_UPLOAD_FILE,
      data,
      ...callbacks,
    }
  },

  createProject(
    data: RequestTypes['createProject'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_PROJECT,
      data,
      ...callbacks,
    }
  },

  getUsers(
    data: RequestTypes['getUsers'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_USERS,
      data,
      ...callbacks,
    }
  },

  updateUserOrganisationPermissions(
    data: RequestTypes['updateUserOrganisationPermissions'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.UPDATE_USER_ORGANISATION_PERMISSIONS,
      data,
      ...callbacks,
    }
  },

  getUserProjectPermissions(
    data: RequestTypes['getUserProjectPermissions'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.GET_USER_PROJECT_PERMISSIONS,
      data,
      ...callbacks,
    }
  },

  updateUserProjectPermissions(
    data: RequestTypes['updateUserProjectPermissions'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.UPDATE_USER_PROJECT_PERMISSIONS,
      data,
      ...callbacks,
    }
  },

  createOrganisationInvite(
    data: RequestTypes['createOrganisationInvite'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_ORGANISATION_INVITE,
      data,
      ...callbacks,
    }
  },

  createProjectInvite(
    data: RequestTypes['createProjectInvite'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CREATE_PROJECT_INVITE,
      data,
      ...callbacks,
    }
  },

  messagetestMessagetest(
    data: RequestTypes['messagetestMessagetest'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.MESSAGETEST,
      data,
      ...callbacks,
    }
  },

  checkIsadmin(
    data: RequestTypes['checkIsadmin'],
    callbacks: Callbacks = {},
  ): AnyAction {
    return {
      type: Actions.CHECK_ISADMIN,
      data,
      ...callbacks,
    }
  },

  // END OF APP_ACTIONS
}))
