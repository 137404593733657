import '../project'
import BaseUtils from './base/_utils'
import { Question, Group, SuccessFactor, AppState } from 'common/state-type'

// @ts-ignore
const Utils = (global.Utils = Object.assign({}, BaseUtils, {
  money: (v) => {
    const formatter = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    })
    return formatter.format(v) /* $2,500.00 */
  },
  getActiveProject: (profile: AppState['profile']) => {
    const projectId = API.storage.getItemSync('projectId')
    if (projectId) {
      return projectId
    }
    // return profile?.projects?.[0]?.id
    const firstActiveProject = profile?.projects?.find(project => project.role !== "NONE");
    return firstActiveProject?.id;
  },
  validateQuestion: (question: Question) => {
    if (!question?.label) {
      return false;
    }
    const selectedAnswers = question?.answers?.filter(
      (answer) => answer?.selected === true,
    )
    if (selectedAnswers?.length < 1) {
      return false
    } else {
      return true
    }
  },
  validateQuestionAns: (question: Question) => {
    if (!question?.label) {
      return false;
    }
    const selectedAnswers = question?.answers?.filter(
      (answer) => answer?.selected === true,
    )
    if (selectedAnswers?.length < 1) {
      return true
    } else {
      return true
    }
  },
  validateGroup: (group: Group) => {
    let invalidGroup = false

    // Filter out questions that do not have a label
  const validQuestions = group?.questions?.filter((question) => question.hasOwnProperty('label'));

  // Map over the filtered questions and validate each one
    const questions = validQuestions?.map((question) => {
      const invalid = !Utils.validateQuestionAns(question)
      if (invalid) {
        invalidGroup = true
      }
      return {
        ...question,
        invalid,
      }
    })
    return {
      ...group,
      invalid: invalidGroup,
      questions: questions,
    }
  },
  validateQuestions: (
    _groups: Group[],
  ): {
    groups: Group[]
    invalidOverall: boolean
    erroredGroups: Record<string, boolean>
  } => {
    let invalidOverall = false
    const erroredGroups = {}
    const groups = _groups?.map((group, groupIndex) => {
      let invalidGroup = false

      const validQuestions = group?.questions?.filter((question) => question.hasOwnProperty('label'));
      const questions = validQuestions?.map((question) => {
        const invalid = !Utils.validateQuestion(question)
        if (invalid) {
          invalidGroup = true
          invalidOverall = true
        }
        return {
          ...question,
          invalid,
        }
      })
      erroredGroups[groupIndex] = !invalidGroup
      return {
        ...group,
        invalid: invalidGroup,
        questions: questions,
      }
    })
    return {
      groups,
      invalidOverall,
      erroredGroups,
    }
  },
  validateSuccessFactors: (_successfactors: SuccessFactor[]) => {
    let invalidOverall = false
    const erroredSuccessfactors = {}
    const successfactors = _successfactors?.map(
      (successfactor, successfactorIndex) => {
        let invalidSuccessFactor = false
        const res = Utils.validateQuestions(successfactor?.groups)
        if (res?.invalidOverall) {
          invalidSuccessFactor = true
          invalidOverall = true
        }
        erroredSuccessfactors[successfactorIndex] = invalidSuccessFactor
        return {
          ...successfactor,
          invalid: invalidSuccessFactor,
          groups: res.groups,
        }
      },
    )
    return {
      successfactors,
      invalidOverall,
      erroredSuccessfactors,
    }
  },
}))

export default Utils
