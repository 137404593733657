import '../project/polyfill'
import App from 'next/app'
import React from 'react'
import { Provider } from 'react-redux'
import withRedux, { MakeStore } from 'next-redux-wrapper'
import withReduxSaga from 'next-redux-saga'
import '../project/project-components'
import createStore from '../common/store'
import Toast from '../components/toast'
import _data from '../common/utils/_data'

import '../styles/Global.scss'
import { Store } from 'redux'
import { PersistGate } from 'redux-persist/integration/react'
import LanguageHandler from '../common/LanguageHandler'
import LoginPersist from '../components/auth/LoginPersist'
import { ThemeProvider } from './superadmin/ThemeContext';
import { useTheme } from '../pages/superadmin/ThemeContext'
import ProjectTitle from 'components/ProjectTitle'


let initialRender = false

export async function getInitialProps({ Component, ctx }) {


  let pageProps

  // todo: this shouldn't happen for static resources
  if (ctx.pathname === '/_error' || !ctx.pathname) {
    return
  }

  // Only use this function if you are using SSR, then this will retrieve the users token and perform
  const locale =
    Constants.simulate.FORCE_LANGUAGE || API.getStoredLocale(ctx.req) // Retrieve the locale from cookie or headers
  const token = await API.getStoredToken(ctx.req) // Retrieve token cookie from req.headers
  await new Promise((resolve, reject) => {
    ctx.store.dispatch(
      AppActions.startup(
        { locale, token },
        { onSuccess: resolve, onError: reject },
      ),
    ) // Post startup action with token and locale
  })
  if (Component?.getInitialProps) {
    // Wait for pages to complete any async getInitialProps
    pageProps = await Component.getInitialProps({ ctx })
  }

  return { pageProps }
}

class MyApp extends App<{ store: Store }> {
  static getInitialProps = getInitialProps

  constructor(props) {
    super(props)
    // If you are using SSR do not use this function, the token should already be retrieved by the server
    if (
      typeof window !== 'undefined' &&
      !this.props.store.getState().clientLoaded
    ) {
      _data.setToken(this.props.store.getState().token)
    }
  }

  onFirstRender = () => {
    API.auth.Cognito.init(Project.cognito)
    initialRender = true
  }

  render() {
    const { Component, pageProps, store } = this.props

    if (!initialRender) {
      // Ensure we set the locale before rendering anything
      this.onFirstRender()
    }

    const getLayout = (page) => {
      // @ts-ignore
      return Component.getLayout ? (
        // @ts-ignore
        <Component.getLayout page={page} router={this.props.router} />
      ) : (
        page
      )
    }

    return (
      <>
      <ThemeProvider>
        <ProjectTitle />
        <Provider store={store}>
          <LoginPersist>
            {/*// @ts-ignore*/}
            <PersistGate persistor={store.__PERSISTOR} loading={null}>
              <LanguageHandler>
                <React.Fragment>
                  {/*<Header />*/}
                  {getLayout(<Component {...pageProps}></Component>)}
                  <div id='modal' />
                  <div id='confirm' />
                  <div id='alert' />
                  <div id='toast'>
                    <Toast />
                  </div>
                  {E2E && (
                    <React.Fragment>
                      <div className='e2e' id='e2e-request' />
                      <div className='e2e' id='e2e-error' />
                    </React.Fragment>
                  )}
                </React.Fragment>
              </LanguageHandler>
            </PersistGate>
          </LoginPersist>
        </Provider>
      </ThemeProvider>
      </>
    )
  }
}

export default global.__JEST__
  ? null
  : withRedux(createStore as unknown as MakeStore)(withReduxSaga(MyApp))
