import React, { createContext, useContext, useState, useEffect } from 'react'
import axios from 'axios'

// Default colors
const defaultColors = {
  primaryColor: '#267493',
  secondaryColor: '#55c5cd',
  linkColor: '#1ac0c6',
  iconColor: '#FFFFFF',
  textColor: '#ffffff ',
  tooltipColor: '#000000',
  title : 'Mainstay Consulting'
}

const defaultLogos = {
  faviconLogoFileName: "/images/faviconLogo.png",
  headerLogoFileName: "/images/headerLogo.png",
  loginLogoFileName: "/images/LoginLogo.png",
  isReset: true
}

const ThemeContext = createContext()

export const ThemeProvider = ({ children }) => {
  const [themeColors, setThemeColors] = useState({})
  const [themeIcons, setThemeIcons] = useState({})
  const [isLoading, setIsLoading] = useState(true);
  const fetchThemeColors = async () => {
    try {
      // Fetch colors from the API

      const response = await axios.get(
        `${Project.api}admin/branding/getBranding`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      setIsLoading(false)

      let { data } = response
      if(!data.title){
        data.title ="Mainstay Consulting"
      }
      setThemeColors(data || defaultColors)
     
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }



  const fetchThemeIcons = async () => {
    try {
      // Fetch colors from the API

      const response = await axios.get(
        `${Project.api}admin/icons/getIcons`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      let { data } = response
      if (data) {
        let filteredData = filterAndReplaceNull(data)
        setThemeIcons(filteredData)
        updateFavicon(filteredData.faviconLogoFileName)
      } else {
        setThemeIcons(defaultLogos)
        updateFavicon(defaultLogos.faviconLogoFileName) 
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  


  function updateFavicon(newFaviconPath) {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = newFaviconPath;
    document.head.appendChild(link);
  }

  useEffect(() => {
    fetchThemeColors()
    fetchThemeIcons()
  }, [])

  const applyThemeColors = (themeColors) => {
    const root = document.documentElement
    root.style.setProperty('--primary', themeColors.primaryColor)
    root.style.setProperty('--secondary', themeColors.secondaryColor)
    root.style.setProperty('--link-primary', themeColors.linkColor)
    root.style.setProperty('--icon-color', themeColors.iconColor)
    root.style.setProperty('--tooltip-bg', themeColors.tooltipColor)
    root.style.setProperty('--tooltip-text', themeColors.textColor)
  }

  useEffect(() => {
    applyThemeColors(themeColors)
  }, [themeColors])

  const saveThemeColors = async (colors) => {
    try {
      setThemeColors(colors)
      await axios.post('/api/colors', colors) // Save colors to the API
    } catch (error) {
      console.error('Error saving data:', error)
    }
  }
  function filterAndReplaceNull(obj) {
    const filteredObject = {};

    for (const key in obj) {
      if (typeof obj[key] === 'string' && obj[key].includes('null')) {
        filteredObject[key] = defaultLogos[key];
      } else {
        filteredObject[key] = obj[key];
      }
    }

    return filteredObject;
  }

  return (
    <ThemeContext.Provider
      value={{
        themeColors,
        themeIcons,
        setThemeColors,
        saveThemeColors,
        fetchThemeColors,
        fetchThemeIcons,
        isLoading
      }}
    >
      {!isLoading && children}
    </ThemeContext.Provider>
  )
}

export const useTheme = () => {
  return useContext(ThemeContext)
}

export default ThemeProvider
