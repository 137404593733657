import React from 'react'
import useProfile from './useProfile'
import Router, { useRouter } from 'next/router'
import { AppState } from '../state-type'
import axios from 'axios'

export const onLoggedIn = (profile: AppState['profile']) => {
  const projectId = Utils.getActiveProject(profile)
  const orgId = profile?.organisations?.[0]?.id
  const pathName = Router.pathname
  const redir = Router.query.redirect
  const redirectToBranding = '/superadmin/branding'
  const isSuperAdminRoute =pathName.includes('superadmin')
  if(profile.role ==="ADMIN" && !isSuperAdminRoute){
    Router.replace(`${redirectToBranding}`)
    return
  }
  if (redir) {
    Router.replace(`${redir}`)
    return
  }


  if (pathName === '/' || pathName.includes('signup') || pathName.includes('login'))  {
    //debugger
    if (!orgId) {
      API.createOrgRedirect()
      return
    }
    if (!projectId) {
      API.createProjectRedirect(`${orgId}`)
      return
    }
    if (projectId) {
      API.projectOverviewRedirect(projectId)
    }
  }
}
export const onSuperadminLoggedIn = async (profile: AppState['profile']) => {
  const projectId = Utils.getActiveProject(profile)
  const orgId = profile?.organisations?.[0]?.id
  const pathName = Router.pathname
  const redir = Router.query.redirect
  if (redir) {
    Router.replace(`${redir}`)
    return
  }

  const checkIsAdminValid = await axios.get(`https://dev-api-assess.mainstayconsulting.co.uk/api/admin/auth/isvaliduser?emailAddress=${profile.attributes.email}`);
  if (checkIsAdminValid.data === "Valid User") {
    API.superAdminSignInRedirect()
  } else {
    alert("not valid user")
  }
}
const useLoggedInRedirect = () => {
  const { profile, getProfile } = useProfile()
  const orgId = profile?.organisations?.[0]?.id
  const router = useRouter()
  React.useEffect(() => {
    const isAuthenticated = async () => {

      const currentPath = window.location.pathname;
     if(!currentPath.includes('anonymous')){
        try {
         const authenticated = await API.auth.Cognito.getSession()
         getProfile(
            {},
            {
             onSuccess: (profile) => {
                onLoggedIn(profile)
             },
             onError: () => {
                API.logout()
             },
            },
         )
        } catch (e) {
         // do nothing
        }
     }
    }
    isAuthenticated()
  }, [getProfile])
}

export default useLoggedInRedirect
